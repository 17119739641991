import React, { Component } from "react";
import UploadFileForm from "./UploadFileForm";

export const HighRiskElementsForm = ({
  authToken,
  uploadPath,
  uploadTitle,
}) => {
  return (
    <UploadFileForm
      uploadPath={uploadPath}
      authToken={authToken}
      uploadTitle={uploadTitle}
    />
  );
};

export default HighRiskElementsForm;
