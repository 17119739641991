export const isoDateFormat = 'yyyy-MM-dd'
export const dateFormat = 'MM/dd/yyyy'
export const datetimeFormat = 'MM/dd/yyyy, h:mm a'
export const monthAndYearFormat = 'MMM yyyy'
export const eighteenYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 18))
export const hundredTwentyYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 120))

export const experianHolidays = [
  // 2019
  '01/01/2019', // Jan. 1,  New Year’s Day (Tuesday)
  '01/21/2019', // Jan. 21, Martin Luther King Day (Monday)
  '02/18/2019', // Feb. 18, President's Day (Monday)
  '05/27/2019', // May  27, Memorial Day (Monday)
  '07/04/2019', // Jul. 4,  Independence Day, (Thursday)
  '09/02/2019', // Sep. 2,  Labor Day (Monday)
  '11/28/2019', // Nov. 28, Thanksgiving Day (Thursday)
  '11/29/2019', // Nov. 29, Thanksgiving holiday (Friday)
  '12/24/2019', // Dec. 24, Christmas Eve (Tuesday)
  '12/25/2019', // Dec. 25, Christmas Day (Wednesday)
  '12/31/2019', // Dec. 31, New Year’s Eve (Tuesday)

  // 2020
  '01/01/2020', // Jan. 1,  New Year’s Day (Wednesday)
  '01/20/2020', // Jan. 20, Martin Luther King Day (Monday)
  '02/17/2020', // Feb. 17, President's Day (Monday)
  '05/25/2020', // May  25, Memorial Day (Monday)
  '07/03/2020', // Jul. 3,  Independence Day, observed (Friday)
  '09/07/2020', // Sep. 7,  Labor Day (Monday)
  '11/26/2020', // Nov. 26, Thanksgiving Day (Thursday)
  '11/27/2020', // Nov. 27, Thanksgiving holiday (Friday)
  '12/24/2020', // Dec. 24, Christmas Eve (Thursday)
  '12/25/2020', // Dec. 25, Christmas Day (Friday)
  '12/31/2020', // Dec. 31, New Year’s Eve (Thursday)
]
