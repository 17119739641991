import React, { Component } from "react";
import PropTypes from "prop-types";
import ControlFileSelect from "./ControlFileSelect";

class ControlFileSelectHttp extends Component {
  state = {
    controlFiles: [],
    value: this.props.value,
  };

  // source: batch_search_control_files_url(hide_blocked: true)
  fetchControlFiles = () => {
    const locationId = this.props.locationId;
    if (locationId) {
      fetch(
        `/control_files/batch_search?hide_blocked=true&clear_profile=${this.props.clearProfile}&skip_trace=${this.props.skipTrace}&location_ids[]=${locationId}`
      )
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          this.setState({ controlFiles: json.control_files });
          // send a change event to parent to null out the selected control file value when the locationId changes
          console.log("sending null value event");
          this.selectChange({ target: { value: null } });
        });
    }
  };

  componentDidMount = () => {
    if (this.props.locationId) {
      this.setState({ value: null });
      this.fetchControlFiles(this.props.locationId);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.locationId !== prevProps.locationId) {
      this.fetchControlFiles(this.props.locationId);
    }
  };

  selectChange = (event) => {
    this.props.onChange(event);
  };

  render() {
    return (
      <div className="control-file-select-http">
        <ControlFileSelect
          controlFiles={this.state.controlFiles}
          value={this.props.value}
          onChange={this.selectChange}
        />
      </div>
    );
  }
}

ControlFileSelectHttp.propTypes = {
  // value is the selected control file object
  value: PropTypes.object,
  locationId: PropTypes.number,
  onChange: PropTypes.func,
  clearProfile: PropTypes.object,
  skipTrace: PropTypes.object,
};

export default ControlFileSelectHttp;
