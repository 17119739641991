import React, { Component } from "react";
import MaterialTable from "material-table";
import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "../../utils/Theme";
import tableIcons from "../../styles/TableIcons";
import PageHeader from "../layout/PageHeader";
import DataSeederSubTable from "./DataSeederSubTable";

type DataSeederProps = {
  columns?: any[];
  data?: any[];
};

class DataSeeder extends Component<DataSeederProps, {}> {
  render() {
    const columns = [
      { title: "Name", field: "name" },
      { title: "Description", field: "description" },
      { title: "Group", field: "group" },
      { title: "Control File", field: "control_file" },
      { title: "User", field: "user" },
      {
        field: "action",
        title: "Action",
        render: (rowData: { http_link: string }) => (
          <a className="btn btn-primary" href={rowData.http_link}>
            Start
          </a>
        ),
      }
    ];
    return (
      <>
        <PageHeader pageTitle="Non-Prod Data Seeder"></PageHeader>

        <ThemeProvider theme={materialTheme}>
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={columns}
            data={this.props.data}
            options={{ exportButton: false }}
            detailPanel={(rowData) => {
              if (rowData.file_location) {
                return <DataSeederSubTable rowData={rowData} />;
              }
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </ThemeProvider>
      </>
    );
  }
}

export default DataSeeder;
