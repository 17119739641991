import React, { Component } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "../../../utils/Theme";
import store from "../../../ReduxStore";
import ClearProfile2Table from "./ClearProfile2Table"
import ClearProfile2Search from "./ClearProfile2Search"
import PageHeader from "../../layout/PageHeader"

type ClearProfile2Props = {
  authenticityToken?: any,
  endDate?: String,
  store?: any,
  galGroup?: any,
  emptyTitle?: any,
  emptyMsg?: any,
  pageTitle?: any,
  startDate?: String,
};

class ClearProfile2 extends Component<ClearProfile2Props, {}> {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={materialTheme}>
          <div className="results"></div>
          <PageHeader pageTitle={this.props.pageTitle} />

          <div className="row">
            <div className="col-sm-3">
              <ClearProfile2Search
                store={store}
                galGroup={this.props.galGroup}
                authenticityToken={this.props.authenticityToken}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
              />
            </div>
            <div className="col-sm-9">
              <ClearProfile2Table store={store} emptyTitle={this.props.emptyTitle} emptyMsg={this.props.emptyMsg} />
            </div>
          </div>
        </ThemeProvider>
      </Provider>
    );
  }
}
export default ClearProfile2;
