import React, { Component } from "react";
import { connect } from "react-redux"
import MaterialTable from "material-table";
import tableIcons from "../../../styles/TableIcons";
import SubTable from "./ClearProfileSubTable";
import PaperSheet from "../../layout/PaperSheet"

type ClearProfileTableProps = {
  data?: any,
  emptyTitle?: any,
  emptyMsg?: any,
  store?: any,
};

class ClearProfileTable extends Component<ClearProfileTableProps, {}> {
  render() {
    const { store } = this.props;
    const columns = [
      { title: "Group",                field: "group" },
      { title: "Account",              field: "account" },
      { title: "Location",             field: "location" },
      { title: "Addresses",            field: "addresses"},
      { title: "Phones",               field: "phones"},
      { title: "Emails",               field: "emails"},
      { title: "Employer Names",       field: "employer_names"},
      { title: "Bank Routing Numbers", field: "bank_routing_numbers" },
      { title: "Processed Records",    field: "record_count" }
    ];

    if (this.props.data) {
      return (
        <>
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={columns}
            data={this.props.data}
            options={{
              exportButton: true,
              exportFileName: "Clear Profile Report",
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 50]
            }}
            detailPanel={(rowData) => {
              return <SubTable rowData={rowData} />;
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </>
      );
    } else {
      return(
        <PaperSheet title={this.props.emptyTitle} content={this.props.emptyMsg} />
      )
    };
  }
}

const mapStateToProps = (state) => ({
  data: state.clearProfile.data.clear_profile,
});

export default connect(mapStateToProps)(ClearProfileTable);
