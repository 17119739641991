import React, { Component } from "react";
import { connect } from 'react-redux'
import MaterialTable from "material-table";
import tableIcons from "../../../styles/TableIcons";
import SubTable from "./ClearProfile2SubTable";
import PaperSheet from "../../layout/PaperSheet"

type ClearProfile2TableProps = {
  data?: any,
  emptyTitle?: any,
  emptyMsg?: any,
  store?: any,
};

class ClearProfile2Table extends Component<ClearProfile2TableProps, {}> {
  render() {
    const { store } = this.props;
    const columns = [
      { title: "Group",                field: "group" },
      { title: "Account",              field: "account" },
      { title: "Location",             field: "location" },
      { title: "Addresses",            field: "addresses"},
      { title: "Phones",               field: "phones"},
      { title: "Emails",               field: "emails"},
      { title: "Employer Names",       field: "employer_names"},
      { title: "Net Monthly Incomes",  field: "net_monthly_incomes" },
      { title: "Bank Routing Numbers", field: "bank_routing_numbers" },
      { title: "Deceased",             field: "deceased" },
      { title: "Processed Records",        field: "record_count" }
    ];

    if (this.props.data) {
      return (
        <>
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={columns}
            data={this.props.data}
            options={{
              exportButton: true,
              exportFileName: "Clear Profile2 Report",
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 50]
            }}
            detailPanel={(rowData) => {
              return <SubTable rowData={rowData} />;
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </>
      );
    } else {
      return(
        <PaperSheet title={this.props.emptyTitle} content={this.props.emptyMsg} />
      )
    };
  }
}

const mapStateToProps = (state) => ({
  data: state.clearProfile2.data.clear_profile2,
});

export default connect(mapStateToProps)(ClearProfile2Table);
