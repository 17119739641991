import React, { Component } from "react";
import PropTypes from "prop-types";

const DeptSelect = ({ departments, value, onChange }) => {
  return (
    <div className="form-group select required data_contribution_files_department">
      <label
        className="select required control-label"
        htmlFor="data_contribution_files_department"
      >
        <abbr title="required">*</abbr> Department
      </label>
      <select
        name="department"
        value={value}
        onChange={onChange}
        placeholder="Select a Department"
        className="select required form-control"
        required="required"
        aria-required="true"
        id="data_contribution_files_department"
        data-fv-field="department"
      >
        <option value="">Please select a department</option>
        {departments.map(([dept, email], idx) => {
          return (
            <option key={idx} value={email}>
              {dept}
            </option>
          );
        })}
      </select>
    </div>
  );
};

DeptSelect.propTypes = {
  value: PropTypes.string,
  departments: PropTypes.array,
  onChange: PropTypes.func,
};

export default DeptSelect;
