import React, { Component } from "react";
import CustomerCertification from "./CustomerCertification";
import UploadFileForm from "./UploadFileForm";

class BatchLenderConversionForm extends Component {
  state = { customerCert: false };

  formData = () => {
    return {
      customer_certification: this.state.customerCert,
    };
  };

  render() {
    return (
      <UploadFileForm
        formData={this.formData}
        uploadPath="/batch_lender_conversions"
        authToken={this.props.authToken}
        uploadTitle="Select a File to Upload"
        enableSend={this.state.customerCert}
      >
        <CustomerCertification
          value={this.state.customerCert}
          name={this.props.userName}
          onChange={(event) => {
            this.setState({ customerCert: event.target.value });
          }}
        />

        <div
          className="col-sm-12 alert alert-danger"
          id="form-validation-error"
          style={this.state.customerCert ? { display: "none" } : {}}
        >
          You must review and acknowledge the Customer Certification
        </div>
      </UploadFileForm>
    );
  }
}

export default BatchLenderConversionForm;
