import {
  createTheme
} from '@material-ui/core/styles'

const theme = createTheme({
  typography: {
    fontSize: 18,
    useNextVariants: true,
    subtitle1: {
      "font-size": "2rem",
    },
    body1: {
      color: "#333333",
      "font-size": "1.3rem",
      "line-height": "2",
    },
    body2: {
      "font-size": "1.2rem",
    },
    caption: {
      "font-size": "1.2rem",
    },
    h5: {
      color: "#333333",
      "font-size": "1.5rem",
      "line-height": "2",
    },
  },
  palette: {
    primary: {
      light: "#6dc0ff",
      main: "#2b90d9",
      dark: "#0063a7",
    },
    secondary: {
      light: "#515561",
      main: "#282c37",
      dark: "#000011",
    },
  },
  status: {
    danger: "#C52233",
  },
  overrides: {
    MuiButton: {
      root: {
        "font-size": "1.2rem",
        borderRadius: 0,

        "&$disabled": {
          cursor: "not-allowed",
          pointerEvents: "unset",
        },
      },
      disabled: {},
    },
    MuiFormControl: {
      root: {
        margin: "1em 0",
      },
    },
    MuiInputBase: {
      root: {
        "font-size": "1.2rem",
        "padding-left": "0px !important"
      },
      inputAdornedStart: {
        "padding": "13px 0px !important",
      },
    },
    MuiPickersDay: {
      day: {
        "font-size": "1.2rem",
      },
    },
    MuiTablePagination: {
      root: {
        "font-size": "1.2rem",
      },
    },
  },
});

export default theme
