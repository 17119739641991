import React, {Component} from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "utils/Theme";
import store from "../ReduxStore";
import ClientCancellationReportForm from "./ClientCancellationReportForm";
import ClientCancellationReportMonthForm from "./ClientCancellationReportMonthForm";

export default class ClientCancellationReportContainer extends Component {
  render() {
    return (
      <div>
        <ClientCancellationReportMonthForm store={store} {...this.props} />
        <Provider store={store}>
          <ThemeProvider theme={materialTheme}>
            <ClientCancellationReportForm store={store} {...this.props} />
          </ThemeProvider>
        </Provider>
      </div>
    );
  }
}

ClientCancellationReportContainer.propTypes = {
  galGroup: PropTypes.object,
  daRole:   PropTypes.bool,
  fyMonth:  PropTypes.string,
};
