import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from "react-redux";
import { labelStyle } from "../styles/GalStyles";

import { GalSelect } from "./GalSelect";
import ControlFileSelectContainer from "./ControlFileSelectContainer";
import ProductPickerContainer from "./ProductPicker";

import UIDatePicker from "./UIDatePicker";

import { submitTADetails } from "../actions/FormPostActions";
import {
  selectStartDate,
  selectEndDate,
  disableSubmit,
  enableSubmit,
  toggleAnalytics,
} from "../actions/GalActions";
import { flashErrors } from "../utils/FlashUtils";

const styles = {
  checkbox: {
    margin: "25px 0",
  },
};

export class TrialAnalysisForm extends Component {
  constructor() {
    super();
    this.submitTADetails = this.submitTADetails.bind(this);
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  submitTADetails(event) {
    const submitButton = event.target;
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    const { store } = this.props;
    const state = store.getState();

    if (
      state.galSelected.start_date === "Invalid date" ||
      state.galSelected.end_date === "Invalid date"
    ) {
      flashErrors(JSON.stringify(["Start Date and/or End Date are invalid."]));
    } else {
      store.dispatch(disableSubmit());
      store.dispatch(submitTADetails(state.galSelected, submitButton));
    }
  }

  render() {
    const { store } = this.props;
    const state = store.getState();

    return (
      <div className="row">
        <div className="col-sm-8 col-sm-offset-2">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Trial Analysis Details</h3>
            </div>

            <form
              ref="form"
              className="form-horizontal"
              onSubmit={this.submitTADetails}
            >
              <div className="panel-body">
                <div className="row">
                  <div className="col-sm-6">
                    <GalSelect store={store} galGroup={this.props.galGroup} />
                    {this.props.daRole ? (
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.props.includePrivate}
                                onChange={this.props.toggleCheckbox}
                                style={styles.checkbox}
                              />
                            }
                            label="Analytic Fields"
                          />
                        </FormGroup>
                      </FormControl>
                    ) : null}
                  </div>

                  <div className="col-sm-6">
                    <ControlFileSelectContainer
                      store={store}
                      controlFiles={this.props.controlFiles}
                    />
                    <label style={labelStyle}>Start Date</label>
                    <UIDatePicker
                      showTodayButton
                      id="trial_analysis-startDate"
                      defaultDate={state.galSelected.start_date}
                      disableFuture={true}
                      onChangeDate={(value) => {
                        store.dispatch(enableSubmit());
                        store.dispatch(
                          selectStartDate(moment(value).format("MM/DD/YYYY"))
                        );
                      }}
                      required
                    />
                    <label style={labelStyle}>End Date</label>
                    <UIDatePicker
                      showTodayButton
                      id="trial_analysis-endDate"
                      defaultDate={state.galSelected.end_date}
                      disableFuture={true}
                      onChangeDate={(value) => {
                        store.dispatch(enableSubmit());
                        store.dispatch(
                          selectEndDate(moment(value).format("MM/DD/YYYY"))
                        );
                      }}
                      required
                    />
                  </div>
                </div>

                <ProductPickerContainer
                  store={store}
                  products={this.props.products}
                />
              </div>

              <div className="panel-footer text-align-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.props.buttonDisabled}
                  onClick={this.submitTADetails}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

TrialAnalysisForm.propTypes = {
  buttonDisabled: PropTypes.bool,
  controlFiles: PropTypes.array,
  daRole: PropTypes.bool,
  galGroup: PropTypes.object,
  products: PropTypes.array,
  store: PropTypes.object,
};

const mapStateToProps = (state) => ({
  controlFiles: state.galSearch.controlFiles,
  buttonDisabled: state.galSelected.disabled,
  products: state.galSearch.products,
  includePrivate: state.galSelected.include_private,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleCheckbox() {
    dispatch(toggleAnalytics());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TrialAnalysisForm);
