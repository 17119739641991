import React from "react";
import PropTypes from "prop-types";

export default class FormErrors extends React.PureComponent {
  render() {
    if (this.props.errors.length) {
      return (
        <div className="errors alert alert-error" role="alert">
          {this.props.errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </div>
      );
    }
    return <div />;
  }
}

FormErrors.propTypes = {
  errors: PropTypes.array.isRequired,
};
