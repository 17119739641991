import { CLEAR_DATA, RECEIVE_CP2_REPORT } from '../constants/ActionTypes'

export function clearProfile2(state = {
  data: []
}, action) {
  switch (action.type) {
    case RECEIVE_CP2_REPORT: {
      return {
        ...state,
        data: action.data,
      };
    }
    case CLEAR_DATA: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
}
