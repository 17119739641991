import React, {Component} from "react";
import PropTypes from "prop-types";
import {submitCCMDetails} from "../actions/FormPostActions";
import {disableSubmit} from "../actions/GalActions";

export default class ClientCancellationReportMonthForm extends Component {
  constructor() {
    super();
    this.submitCCMDetails = this.submitCCMDetails.bind(this);
  }

  submitCCMDetails(event) {
    const submitButton = event.target;
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    const {store} = this.props;
    const month   = this.props.fyMonth;
    store.dispatch(disableSubmit());
    store.dispatch(submitCCMDetails(month,  submitButton));

  }

  render() {
    const {store} = this.props;
    const state   = store.getState();

    return (
      <div className="row">
        <div className="col-sm-6">
          <form
            ref="form"
            className="form-horizontal"
            onSubmit={this.submitCCMDetails}
          >
            <input
              type="hidden"
              id="client_cancellation_report_month"
              value={this.props.fyMonth}
            />

            <div className="text-align-left">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.submitCCMDetails}
              >
                Generate/Email Client Cancellation Report CSV
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ClientCancellationReportMonthForm.propTypes = {
  galGroup: PropTypes.object,
  daRole:   PropTypes.bool,
  fyMonth:  PropTypes.string,
};
