import React from "react";
import propTypes from "prop-types";
import { dateFormat } from "constants/dateConstants";
import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "utils/Theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

const UIDatePicker = (props) => {
  return (
    <ThemeProvider theme={materialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk={props.autoOk || true}
          clearable={props.clearable || true}
          value={props.date}
          onChange={(date) => {
            props.onChangeDate(date);
          }}
          name={props.name}
          required={props.required ? true : false}
          showTodayButton={props.showTodayButton ? true : false}
          disableFuture={props.disableFuture ? true : false}
          disabled={props.disabled ? true : false}
          InputAdornmentProps={{ position: "start" }}
          inputVariant={props.inputVariant || "outlined"}
          openTo={props.openTo || "date"}
          shouldDisableDate={props.shouldDisableDate}
          views={props.views}
          label={props.label || ""}
          maxDate={props.maxDate}
          minDate={props.minDate}
          maxDateMessage={props.maxDateMessage}
          minDateMessage={props.minDateMessage}
          autoComplete={props.autoComplete}
          id={props.id}
          placeholder={props.placeholder || "MM/DD/YYYY"}
          format={props.format || dateFormat}
          style={{ width: "100%", margin: "0px" }}
          className="date_picker"
          helperText={props.showErrorMessage ? props.helperText : undefined}
          error={props.showErrorMessage ? true : undefined}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

UIDatePicker.propTypes = {
  label: propTypes.string,
  onChangeDate: propTypes.func.isRequired,
  date: propTypes.instanceOf(Date),

  // Required but provided default
  autoComplete: propTypes.string.isRequired,

  // Optional
  id: propTypes.string,
  placeholder: propTypes.string,
  format: propTypes.string,
  defaultDate: propTypes.instanceOf(Date),
  maxDateMessage: propTypes.string,
  minDateMessage: propTypes.string,
  minDate: propTypes.instanceOf(Date),
  maxDate: propTypes.instanceOf(Date),
  openTo: propTypes.oneOf(["date", "year", "month"]),
  required: propTypes.bool,
  disabled: propTypes.bool,
  disableFuture: propTypes.bool,
  showTodayButton: propTypes.bool,
  shouldDisableDate: propTypes.func,
  views: propTypes.array,
  showErrorMessage: propTypes.bool,
};

UIDatePicker.defaultProps = {
  autoComplete: "off",
};

export default UIDatePicker;
