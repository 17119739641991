import { getLocationsFromGroup } from '../utils/GalUtils';
import { CLEAR_GAL, REQUEST_GROUP_SEARCH, RECEIVE_GROUP_SEARCH, REQUEST_CONTROL_FILES, RECEIVE_CONTROL_FILES,
  REQUEST_PRODUCTS, RECEIVE_PRODUCTS, SELECT_GROUP, SELECT_ACCOUNTS, SELECT_LOCATIONS,
  SELECT_CONTROL_FILE, SELECT_PRODUCTS, SELECT_PRODUCT, REMOVE_PRODUCT, SELECT_START_DATE,
  SELECT_END_DATE, LOAD_ACCOUNTS_LOCATIONS, CLEAR_ACCOUNTS_LOCATIONS_CONTROL_FILES, REFRESH_LOCATIONS,
  REFRESH_ACCOUNTS, DISABLE_SUBMIT, ENABLE_SUBMIT, ANALYTICS_FIELD_CHECKED } from '../constants/ActionTypes';

export function disableSubmit() {
  return {
    type: DISABLE_SUBMIT,
  };
}
export function enableSubmit() {
  return {
    type: ENABLE_SUBMIT,
  };
}
export function requestGroupSearch() {
  return {
    type: REQUEST_GROUP_SEARCH,
  };
}
export function receiveGroupSearch(groupQuery, jsonResult) {
  return {
    type: RECEIVE_GROUP_SEARCH,
    groups: jsonResult.groups,
    groupQuery,
  };
}
export function fetchGroupSearch(groupQuery) {
  return function (dispatch) {
    dispatch(requestGroupSearch(groupQuery));

    return $.getJSON(`/groups/list.json?page_limit=10&group_query=${groupQuery}`)
      .done(jsonResult => dispatch(receiveGroupSearch(groupQuery, jsonResult)));
  };
}
export function loadAccountsLocations(accounts, locations) {
  return {
    type: LOAD_ACCOUNTS_LOCATIONS,
    accounts,
    locations,
  };
}
export function clearAccountsLocationsControlFiles() {
  return {
    type: CLEAR_ACCOUNTS_LOCATIONS_CONTROL_FILES,
  };
}
export function refreshLocations(locations) {
  return {
    type: REFRESH_LOCATIONS,
    locations,
  };
}
export function refreshAccounts(accounts) {
  return {
    type: REFRESH_ACCOUNTS,
    accounts,
  };
}
export function requestProducts(locations, controlFiles) {
  return {
    type: REQUEST_PRODUCTS,
    locations,
    controlFiles,
  };
}
export function receiveProducts(products) {
  return {
    type: RECEIVE_PRODUCTS,
    products,
  };
}
export function fetchProducts(locations, controlFiles) {
  const locationIds = locations.map(location => location.id);
  const controlFileIds = controlFiles.map(controlFile => controlFile.id);
  return function (dispatch) {
    dispatch(requestProducts(locations, controlFiles));

    return $.ajax({
      url: '/products/available',
      type: 'GET',
      dataType: 'json',
      data: {
        location_ids: locationIds,
        control_file_ids: controlFileIds,
        for_ta: true,
      },
    })
      .done((jsonResult) => {
        dispatch(receiveProducts(jsonResult.products));
      });
  };
}
export function requestControlFiles(locations) {
  return {
    type: REQUEST_CONTROL_FILES,
    locations,
  };
}
export function receiveControlFiles(locations, controlFiles) {
  return {
    type: RECEIVE_CONTROL_FILES,
    controlFiles,
    locations,
  };
}
export function toggleAnalytics() {
  return {
    type: ANALYTICS_FIELD_CHECKED
  }
}
export function fetchControlFiles(locations) {
  locations = Array.isArray(locations) ? locations : [locations]
  const locationIds = locations.map(location => location.id);
  return function (dispatch) {
    dispatch(requestControlFiles(locations));

    return $.ajax({
      url: '/control_files/search',
      type: 'GET',
      dataType: 'json',
      data: {
        location_ids: locationIds,
      },
    })
      .done((jsonResult) => {
        dispatch(receiveControlFiles(locations, jsonResult.control_files));
        dispatch(fetchProducts(locations, jsonResult.control_files));
      });
  };
}
export function refreshLocationsAndControlFiles(locations) {
  return function (dispatch) {
    dispatch(refreshLocations(locations));
    if (locations.length) {
      dispatch(fetchControlFiles(locations));
    } else {
      dispatch(receiveControlFiles([]));
    }
  };
}
export function selectGroup(group) {
  return {
    type: SELECT_GROUP,
    group,
  };
}
export function selectAccounts(accounts) {
  return {
    type: SELECT_ACCOUNTS,
    accounts,
  };
}
export function selectLocations(locations) {
  return {
    type: SELECT_LOCATIONS,
    locations,
  };
}
export function fetchControlFilesSelectLocations(locations, group) {
  return function (dispatch) {
    dispatch(selectLocations(locations));
    if (locations.length === 0) {
      locations = getLocationsFromGroup(group);
    }
    dispatch(fetchControlFiles(locations));
  };
}
export function selectControlFile(controlFile) {
  return {
    type: SELECT_CONTROL_FILE,
    controlFile,
  };
}
export function selectProducts(products) {
  return {
    type: SELECT_PRODUCTS,
    products,
  };
}
export function selectProduct(product) {
  return {
    type: SELECT_PRODUCT,
    product,
  };
}
export function removeProduct(product) {
  return {
    type: REMOVE_PRODUCT,
    product,
  };
}
export function selectStartDate(startDate) {
  return {
    type: SELECT_START_DATE,
    startDate,
  };
}
export function selectEndDate(endDate) {
  return {
    type: SELECT_END_DATE,
    endDate,
  };
}
export function clearGal() {
  return {
    type: CLEAR_GAL,
  };
}
