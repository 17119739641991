import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "utils/Theme";
import store from "../ReduxStore";
import TrialAnalysisForm from "./TrialAnalysisForm";

export default class TrialAnalysisContainer extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={materialTheme}>
          <TrialAnalysisForm store={store} {...this.props} />
        </ThemeProvider>
      </Provider>
    );
  }
}

TrialAnalysisContainer.propTypes = {
  galGroup: PropTypes.object,
  daRole: PropTypes.bool,
};
