import { CLEAR_DATA, RECEIVE_CP2_REPORT } from '../constants/ActionTypes';

export function receiveCP2Report(data) {
  return {
    type: RECEIVE_CP2_REPORT,
    data: data,
  };
}
 
export function clearData() {
  return {
    type: CLEAR_DATA
  };
}
