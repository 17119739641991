import { flattenDeep, sortBy } from "lodash";

export function getLocationsFromGroup(group) {
  const locations = group.accounts.map(account => account.locations);
  return flattenDeep(locations);
}

export const loadLocations = (search = "") =>
  fetch(`/groups/list.json?page_limit=20&location_query=${search}`)
    .then((res) => res.json())
    .then(({ locations }) => ({
      options: sortBy(locations, ["id", "name"]),
    }));

export const loadControlFiles = (location) =>
  fetch(
    `/control_files/search.json?hide_blocked=true&location_ids[]=${location.id}`
  )
    .then((res) => res.json())
    .then(({ control_files }) => ({ options: control_files }));
