import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import _ from "lodash";
import { labelStyle } from "../styles/GalStyles";

import {
  selectAccounts,
  refreshLocationsAndControlFiles,
  selectLocations,
  enableSubmit,
} from "../actions/GalActions";

export class AccountSelect extends Component {
  constructor(props) {
    super(props);
    this.selectAccounts = this.selectAccounts.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.accounts !== nextProps.accounts) {
      const accounts = nextProps.accounts;
      if (accounts.length === 1) {
        this.refs.accountInput.setValue(accounts[0]);
      }
    }
  }

  selectAccounts(accounts) {
    const { store } = this.props;
    store.dispatch(enableSubmit());

    if (accounts !== []) {
      const currentState = store.getState();
      const accountIds = Array.isArray(accounts)
        ? accounts.map((account) => account.id)
        : [accounts.id];
      let possibleLocations = _.flatten(
        currentState.galSelected.cascading_gal.group.accounts.map(
          (account) => account.locations
        )
      );
      possibleLocations = possibleLocations.filter((location) =>
        accountIds.includes(location.account_id)
      );
      if (possibleLocations.length === 1) {
        store.dispatch(selectLocations(possibleLocations));
      }

      store.dispatch(refreshLocationsAndControlFiles(possibleLocations));
    }
    store.dispatch(selectAccounts(accounts));
  }

  render() {
    return (
      <div className="accounts react-select">
        <label style={labelStyle}>Accounts</label>
        <Select
          multi
          options={_.sortBy(this.props.accounts, ["id", "name"])}
          value={this.props.selectedAccounts}
          onChange={this.selectAccounts}
          placeholder="All Accounts"
          valueKey="id"
          labelKey="name"
          ref="accountInput"
          tabSelectsValue={false}
          openOnFocus
          optionRenderer={({ name, id }) => (
            <div className="option" data-value={id}>
              <span>
                ({id}) {name}
              </span>
            </div>
          )}
          valueRenderer={({ name, id }) => (
            <div className="value" data-value={id}>
              <span>
                ({id}) {name}
              </span>
            </div>
          )}
        />
      </div>
    );
  }
}

const objectOrArray = [PropTypes.array, PropTypes.object];

AccountSelect.propTypes = {
  store: PropTypes.object.isRequired,
  accounts: PropTypes.array,
  selectedAccounts: PropTypes.oneOfType(objectOrArray),
};

const mapStateToProps = (state) => ({
  accounts: state.galSearch.accounts,
  selectedAccounts: state.galSelected.cascading_gal.accounts,
});

export default connect(mapStateToProps)(AccountSelect);
