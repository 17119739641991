import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { labelStyle } from "../styles/GalStyles";

const selectChange = (callback) => (data) => {
  callback({ target: { value: data } });
};

export const ControlFileSelect = ({ controlFiles, value, onChange }) => {
  return (
    <div className="control_files react-select" id="control-file-selector">
      <label style={labelStyle}>Control File</label>
      <Select
        name="control-file-select"
        options={controlFiles}
        value={value}
        valueKey="id"
        labelKey="name"
        onChange={selectChange(onChange)}
        tabSelectsValue={false}
        openOnFocus
        optionRenderer={({ name, id }) => (
          <div className="option" data-value={id}>
            <span>{name}</span>
          </div>
        )}
        valueRenderer={({ name, id }) => (
          <div className="value" data-value={id}>
            <span>{name}</span>
          </div>
        )}
      />
    </div>
  );
};

ControlFileSelect.propTypes = {
  controlFiles: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default ControlFileSelect;
