import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { productText } from "../styles/GalStyles";
import { arraysEqual } from "../utils/ArrayUtils";
import {
  selectProduct,
  selectProducts,
  removeProduct,
  enableSubmit,
} from "../actions/GalActions";

export class ProductPicker extends Component {
  constructor(props) {
    super(props);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  UNSAFE_componentWillUpdate() {
    const { store } = this.props;
    const products = store.getState().galSearch.products;
    if (!arraysEqual(this.props.products, products)) {
      store.dispatch(selectProducts(products));
    }
  }

  handleCheckbox(event) {
    const { store } = this.props;
    const value = event.target.value;
    const checked = event.target.checked;

    store.dispatch(enableSubmit());

    if (checked) {
      store.dispatch(selectProduct(value));
    } else {
      store.dispatch(removeProduct(value));
    }
  }

  render() {
    return (
      <div className="row" style={productText}>
        {this.props.products.length ? (
          <div className="products">
            <p>Select Products to Include</p>
            {this.props.products.map((product) => (
              <div className="checkbox" key={product}>
                <label>
                  <input
                    type="checkbox"
                    defaultChecked
                    value={product}
                    onChange={this.handleCheckbox}
                  />
                  {product}
                </label>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

ProductPicker.propTypes = {
  store: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  removedProducts: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    products: state.galSearch.products,
  };
}

export default connect(mapStateToProps)(ProductPicker);
