import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {connect} from "react-redux";

import UIDatePicker from "./UIDatePicker";
import { labelStyle } from "../styles/GalStyles";
import {submitCCDetails} from "../actions/FormPostActions";
import {
  selectStartDate,
  selectEndDate,
  disableSubmit,
  enableSubmit,
  toggleAnalytics,
} from "../actions/GalActions";
import {flashErrors} from "../utils/FlashUtils";

const styles = {
  checkbox: {
    margin: "25px 0",
  },
};

export class ClientCancellationReportForm extends Component {
  constructor() {
    super();
    this.submitCCDetails  = this.submitCCDetails.bind(this);
  }

  onStartDateChange = (date) => {
    this.setState({startDate: date});
  };

  onEndDateChange = (date) => {
    this.setState({endDate: date});
  };

  submitCCDetails(event) {
    const submitButton = event.target;
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    const {store} = this.props;
    const state   = store.getState();

    if (
      state.galSelected.start_date === "Invalid date" ||
      state.galSelected.end_date === "Invalid date"
    ) {
      flashErrors(JSON.stringify(["Start Date and/or End Date are invalid."]));
    } else {
      store.dispatch(disableSubmit());
      store.dispatch(submitCCDetails(state.galSelected, submitButton));
    }
  }

  render() {
    const {store} = this.props;
    const state   = store.getState();

    return (
      <div className="row">
        <div className="col-sm-4 col-sm-offset-4">
          <div className="panel panel-default bg-fa">
            <div className="panel-heading">
              <h3 className="panel-title">Client Cancellation Report</h3>
            </div>

            <form
              ref="form"
              className="form-horizontal"
              onSubmit={this.submitCCDetails}
            >
              <div className="panel-body">
                <div className="row">
                  <div className="col-sm-8 col-sm-offset-2">
                    <label style={labelStyle}>Start Date</label>
                    <UIDatePicker
                      showTodayButton
                      id="client_cancellation_report-startDate"
                      defaultDate={state.galSelected.start_date}
                      disableFuture={true}
                      onChangeDate={(value) => {
                        store.dispatch(enableSubmit());
                        store.dispatch(
                          selectStartDate(moment(value).format("MM/DD/YYYY"))
                        );
                      }}
                      required
                    />
                    <label style={labelStyle}>End Date</label>
                    <UIDatePicker
                      showTodayButton
                      id="client_cancellation_report-endDate"
                      defaultDate={state.galSelected.end_date}
                      disableFuture={true}
                      onChangeDate={(value) => {
                        store.dispatch(enableSubmit());
                        store.dispatch(
                          selectEndDate(moment(value).format("MM/DD/YYYY"))
                        );
                      }}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="panel-footer text-align-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={this.props.buttonDisabled}
                  onClick={this.submitCCDetails}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ClientCancellationReportForm.propTypes = {
  buttonDisabled: PropTypes.bool,
  daRole:         PropTypes.bool,
  galGroup:       PropTypes.object,
  store:          PropTypes.object,
};

const mapStateToProps = (state) => ({
  buttonDisabled: state.galSelected.disabled,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleCheckbox() {
    dispatch(toggleAnalytics());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCancellationReportForm);
