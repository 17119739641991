import React, { Component } from "react";
import CloudUpload from "./CloudUpload";
import LocationSelect from "./LocationSelect";
import ControlFileSelectHttp from "./ControlFileSelectHttp";
import UserSelect from "./UserSelect";
import CustomerCertification from "./CustomerCertification";
import UploadFileForm from "./UploadFileForm";

class BatchInquiryFileForm extends Component {
  state = {
    location: this.props.location,
    controlFile: this.props.controlFile,
    runAsUser: this.props.runAsUser,
    customerCert: false,
  };

  valid = () => {
    return (
      !!this.state.location &&
      !!this.state.controlFile &&
      this.state.customerCert &&
      this.runAsUserValid()
    );
  };

  runAsUserValid = () => {
    return !this.props.clarityUser || (!!this.state.runAsUser && this.state.customerCert);
  };

  formChange = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  formData = () => {
    return {
      location_id: this.state.location.id,
      control_file_id: this.state.controlFile.id,
      run_as_user_id: this.state.runAsUser && this.state.runAsUser.id,
      customer_certification: this.state.customerCert,
    };
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h1>{this.props.title} </h1>
          <div className="clearfix"></div>
        </div>

        <div className="row">
          <div className="col-sm-7">
            <div className="lead">Current Contract Applies</div>
            <div className="alert alert-info">
              This upload screen will process all inquiries in your file against
              the location and control file you select below. Products in this
              control file will be run according to normal processing rules. You
              will be billed at your contract rate for all products run. Contact
              your sales person with any questions.
            </div>
          </div>
        </div>

        <UploadFileForm
          formData={this.formData}
          uploadPath={this.props.uploadPath}
          authToken={this.props.authToken}
          uploadTitle={this.props.detail_text}
          enableSend={this.valid()}
        >
          <LocationSelect
            allowMultiple={false}
            onChange={this.formChange("location")}
            value={this.state.location}
            locations={this.props.locations}
          />

          <ControlFileSelectHttp
            locationId={this.state.location && this.state.location.id}
            value={this.state.controlFile}
            onChange={this.formChange("controlFile")}
          />

          {this.props.clarityUser && (
            <UserSelect
              value={this.state.runAsUser}
              onChange={this.formChange("runAsUser")}
            />
          )}

          <CustomerCertification
            value={this.state.customerCert}
            name={this.props.userName}
            onChange={this.formChange("customerCert")}
          />

          <div
            className="col-sm-12 alert alert-danger"
            id="form-validation-error"
            style={this.state.customerCert ? { display: "none" } : {}}
            data-testid="customer-cert-error"
          >
            Please choose a Location and Control File and agree to the Customer
            Certification
          </div>

          <div
            className="col-sm-12 alert alert-danger"
            id="form-validation-error"
            style={this.props.clarityUser && !this.state.runAsUser ? {} : { display: "none" }}
            data-testid="user-selection-error"
          >
            Please choose a User to run the file as
          </div>
        </UploadFileForm>
      </div>
    );
  }
}

export default BatchInquiryFileForm;
