import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "../../utils/Theme";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

function PaperSheet(props) {
  const { classes, title, content } = props;

  return (
    <div>
      <ThemeProvider theme={materialTheme}>
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h3">
            {title}
          </Typography>
          <Typography component="p">
            {content}
          </Typography>
        </Paper>
      </ThemeProvider>
    </div>
  );
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  content: PropTypes.string
};

export default withStyles(styles)(PaperSheet);
