import React, { Component } from "react";
import ControlFileSelect from "./ControlFileSelect";
import CustomerCertification from "./CustomerCertification";
import UploadFileForm from "./UploadFileForm";
import UIDatePicker from "./UIDatePicker";
import { labelStyle } from "../styles/GalStyles";

class SkipTraceFileForm extends Component {
  state = {
    controlFile: this.props.controlFile,
    controlFiles: this.props.controlFiles,
    ignoreBeforeDate: null,
    customerCert: false,
  };

  onDateChange = (date) => {
    this.setState({ ignoreBeforeDate: date });
  };

  valid = () => {
    return !!this.state.controlFile && this.state.customerCert;
  };

  formData = () => {
    return {
      control_file_id: this.state.controlFile.id,
      customer_certification: this.state.customerCert,
      ignore_before_date: this.state.ignoreBeforeDate,
    };
  };

  formChange = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  render() {
    return (
      <div>
        <UploadFileForm
          formData={this.formData}
          uploadPath="/skip_trace_files"
          authToken={this.props.authToken}
          uploadTitle={this.props.detail_text}
          enableSend={this.valid()}
        >
          <div className="form-group">
            <label style={labelStyle}>Ignore Before Date</label>
            <UIDatePicker
              showTodayButton
              id="skip-trace_ignoreBeforeDate"
              defaultDate={this.state.ignoreBeforeDate}
              onChangeDate={this.onDateChange}
            />
            <span id="beforeDateHelp" className="help-block">
              &nbsp;Optional: Ignore hits before the data specified
            </span>
          </div>

          <div className="form-group">
            <ControlFileSelect
              controlFiles={this.props.controlFiles}
              value={this.state.controlFile}
              onChange={this.formChange("controlFile")}
            />
          </div>

          <CustomerCertification
            value={this.state.customerCert}
            name={this.props.userName}
            onChange={this.formChange("customerCert")}
          />

          <div
            className="col-sm-12 alert alert-danger"
            id="form-validation-error"
            style={this.valid() ? { display: "none" } : {}}
          >
            Please choose a Control File and agree to the Customer Certification
          </div>
        </UploadFileForm>
      </div>
    );
  }
}

export default SkipTraceFileForm;
