import React, { Component } from "react";
import LocationSelect from "./LocationSelect";
import ControlFileSelectHttp from "./ControlFileSelectHttp";
import UploadFileForm from "./UploadFileForm";

class TrackingIdDataStudyFileForm extends Component {
  state = {
    location: this.props.location,
    controlFile: this.props.controlFile,
  };

  valid = () => {
    return !!this.state.location && !!this.state.controlFile;
  };

  formChange = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  formData = () => {
    return {
      location_id: this.state.location.id,
      control_file_id: this.state.controlFile.id,
    };
  };

  render() {
    return (
      <UploadFileForm
        formData={this.formData}
        uploadPath="/tid_data_study_files"
        authToken={this.props.authToken}
        uploadTitle={this.props.detail_text}
        enableSend={this.valid()}
      >
        <LocationSelect
          allowMultiple={false}
          onChange={this.formChange("location")}
          value={this.state.location}
          locations={this.props.locations}
        />

        <ControlFileSelectHttp
          locationId={this.state.location && this.state.location.id}
          value={this.state.controlFile}
          onChange={this.formChange("controlFile")}
        />

        <div
          className="col-sm-12 alert alert-danger"
          id="form-validation-error"
          style={this.valid() ? { display: "none" } : {}}
        >
          Please choose a Location and Control File
        </div>
      </UploadFileForm>
    );
  }
}

export default TrackingIdDataStudyFileForm;
