import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { GalSelect } from "../../GalSelect";
import UIDatePicker from "../../UIDatePicker";
import { submitCPReport } from "../../../actions/FormPostActions";
import {
  selectStartDate,
  selectEndDate,
  disableSubmit,
} from "../../../actions/GalActions";
import { labelStyle } from "../../../styles/GalStyles";
import { flashErrors } from "../../../utils/FlashUtils";

type ClearProfileSearchProps = {
  buttonDisabled?: any,
  galGroup?: any,
  store?: any,
};

export class ClearProfileSearch extends Component<ClearProfileSearchProps, {}> {
  constructor(props: any) {
    super(props);
    this.submitCPReport = this.submitCPReport.bind(this);
  }

  clearForm = () => {
    window.location.reload(false);
  };

  onStartDateChange = (date: any) => {
    this.setState({ startDate: date });
  };

  onEndDateChange = (date: any) => {
    this.setState({ endDate: date });
  };

  submitCPReport(event: { target: any; preventDefault: () => void; nativeEvent: { stopImmediatePropagation: () => void; }; }) {
    const submitButton = event.target;
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    const { store } = this.props;
    const state = store.getState();
    store.dispatch(submitCPReport(state.galSelected, submitButton));
  }

  render() {
    const { store } = this.props;
    const state = store.getState();
    return (
      <>
        <div className="panel panel-default">
          <form id="clear_profile_form" ref="form" className="form-horizontal" onSubmit={this.submitCPReport}>
            <div className="panel-heading">
              <h3 className="panel-title">Search</h3>
            </div>

            <div className="panel-body">
              <div className="row">
                <div className="col-sm-12">
                  <GalSelect store={store} galGroup={this.props.galGroup} />
                  <label style={labelStyle}>Start Date</label>
                  <UIDatePicker
                    showTodayButton
                    id="clear_profile_reports_start_date"
                    defaultDate={state.galSelected.start_date}
                    disableFuture={true}
                    name="start_date"
                    onChangeDate={(value: moment.MomentInput) => {
                      store.dispatch(selectStartDate(moment(value).format("MM/DD/YYYY")));
                    }}
                  />
                  <label style={labelStyle}>End Date</label>
                  <UIDatePicker
                    showTodayButton
                    id="clear_profile_reports_end_date"
                    defaultDate={state.galSelected.end_date}
                    disableFuture={true}
                    name="end_date"
                    onChangeDate={(value: moment.MomentInput) => {
                      store.dispatch(selectEndDate(moment(value).format("MM/DD/YYYY")));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="panel-footer text-align-right">
              <button
                type="button"
                className="btn btn-primary m-r-xs"
                disabled={this.props.buttonDisabled}
                onClick={this.submitCPReport}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.clearForm}
              >
                Clear Form
              </button>
            </div>
          </form>
        </div>

        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">Notes</h3>
          </div>

          <div className="panel-body">
            <div className="row">
              <div className="col-sm-12">
                <ul>
                  <li>Group is a required field.</li>
                  <li>Maximum look back of 180 days.</li>
                  <li>Processed records do not include rows that had exceptions.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: { galSelected: { disabled: any; }; }) => ({
  buttonDisabled: state.galSelected.disabled
});

export default connect(mapStateToProps)(ClearProfileSearch);
