import React, { Component } from "react";
import PropTypes from "prop-types";
import GroupSelectContainer from "./GroupSelect";
import AccountSelectContainer from "./AccountSelect";
import LocationSelectContainer from "./LocationSelectContainer";

export class GalSelect extends Component {
  render() {
    return (
      <div className="cascading-gal">
        <GroupSelectContainer
          store={this.props.store}
          galGroup={this.props.galGroup}
        />
        <AccountSelectContainer store={this.props.store} />
        <LocationSelectContainer store={this.props.store} />
      </div>
    );
  }
}

GalSelect.propTypes = {
  store: PropTypes.object.isRequired,
  galGroup: PropTypes.object,
};
