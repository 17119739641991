import React, { Component } from "react";

type ClearProfile2SubTableProps = {
  rowData?: {
    data_options: any,
    data_elements: any,
    record_count: any
  };
};

class ClearProfile2SubTable extends Component<ClearProfile2SubTableProps, {}> {
  decimalRange(number: Number) {
    if (number == 100) {
      return number.toFixed(0);
    } else {
      return number.toFixed(1);
    }
  }

  calculateHitRate(value: any, rows: any) {
    if (value !== 0) {
      return this.decimalRange(value / rows * 100);
    } else {
      return 0;
    }
  }

  render() {
    const rowData = this.props.rowData;

    return (
      <div style={{ padding: "1.5em", backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
        <table className="table">
          <thead>
            <tr>
              <th className="title" style={{ width: "60%" }}>Data Options</th>
              <th style={{ width: "20%" }}>Hit Count</th>
              <th style={{ width: "20%" }}>Hit Rate</th>
            </tr>
          </thead>

          <tbody>
            {rowData.data_options.map(
              (
                option: {
                  name: React.ReactNode;
                  count: React.ReactNode;
                  class: React.ReactNode;
                },
                index: React.Key
              ) => (
                <tr className={option.class} key={index}>
                  <td>{option.name}</td>
                  <td>{option.count}</td>
                  <td>{this.calculateHitRate(option.count, rowData.record_count)}%</td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <div className="clearfix" />

        <table className="table">
          <thead>
            <tr>
              <th className="title" style={{ width: "60%" }}>Data Element</th>
              <th style={{ width: "20%" }}>Hit Count</th>
              <th style={{ width: "20%" }}>Hit Rate</th>
            </tr>
          </thead>

          <tbody>
            {rowData.data_elements.map(
              (
                element: {
                  name: React.ReactNode;
                  count: React.ReactNode;
                  class: React.ReactNode;
                },
                index: React.Key
              ) => (
                <tr className={element.class} key={index}>
                  <td>{element.name}</td>
                  <td>{element.count}</td>
                  <td>{this.calculateHitRate(element.count, rowData.record_count)}%</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
export default ClearProfile2SubTable;
