import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactS3Uploader from "react-s3-uploader";

class CloudUpload extends React.Component {
  constructor(props) {
    super(props);
    this.uploader = null;
    this.state = {
      filename: null,
      percentage: 0,
      readySend: false,
    };
  }

  sendFile = (e) => {
    e.preventDefault();
    this.uploader.uploadFile();
  };

  onFinish = (res) => {
    this.setState({ readySend: false });
    this.props.onSent(res);
  };

  onChange = (e) => {
    if (e.target.value.length == 0) return;
    const filename = e.target.value.replace(/.*[\/\\]/, "");
    this.setState({ filename: filename, readySend: true, percentage: 0 });
  };

  clear = (e) => {
    e.preventDefault();
    this.setState({ filename: null, percentage: 0, readySend: false });
    this.uploader.clear();
  };

  showProgress = (percent, status, file) => {
    console.log(`uploading file ${file.name} ${status} -- ${percent}`);
    this.setState({ percentage: percent });
  };

  done = () => {
    this.state.percentage == 100;
  };

  render() {
    return (
      <div className="col-sm-6">
        <div className={this.state.filename ? "" : "hidden"}>
          <span className="h4">{this.state.filename}</span>
          &nbsp;
          <span className="h5">{this.state.percentage}%</span>
          <div className="progress">
            <div
              className={`progress-bar bg-${this.done ? "success" : "info"}`}
              style={{ width: this.state.percentage + "%" }}
            />
          </div>
        </div>
        <div className="">
          <div
            id="send-buttons"
            className={this.state.readySend ? "" : "hidden"}
            data-testid="send-buttons"
          >
            <button
              id="cloud-send-file-button"
              onClick={this.sendFile}
              className="btn btn-success"
              disabled={!this.props.enableSend}
              data-testid="cloud-send-file-button"
            >
              Upload
            </button>
            &nbsp;
            <button
              id="cloud-send-cancel"
              onClick={this.clear}
              className="btn btn-danger"
              data-testid="cloud-send-cancel"
            >
              Cancel
            </button>
          </div>
          <div className={this.state.readySend ? "hidden" : ""}>
            <div className="btn btn-success fileinput-button text-left">
              {this.props.selectText}
              <ReactS3Uploader
                onChange={this.onChange}
                onProgress={this.showProgress}
                signingUrl={this.props.signingUrl}
                uploadRequestHeaders={{}}
                autoUpload={false}
                onFinish={this.onFinish}
                accept={this.props.fileExtensions}
                ref={(ref) => (this.uploader = ref)}
                data-testid="react-s3-uploader"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CloudUpload.propTypes = {
  onSent: PropTypes.func,
  enableSend: PropTypes.bool,
  selectText: PropTypes.string,
  signingUrl: PropTypes.string,
  fileExtensions: PropTypes.string,
};

CloudUpload.defaultProps = {
  onSent: () => {},
  enableSend: true,
  selectText: "Select File",
  fileExtensions: ".bz2, .gz, .gzip, .zip, .csv, .pgp, .gpg, .xlsx, .pdf, .doc, .docx",
};

export default CloudUpload;
