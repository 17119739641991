export function flashErrors(errors) {
  const errorArray = JSON.parse(errors);
  let html = "<div class='alert alert-error'><button class='close' data-dismiss='alert' type='button'>x</button>";
  errorArray.map((error) => {
    html += `<li>${error}</li>`;
  });
  $('.results').html(html);
}

export function flashSuccess(button) {
  const html = `<div class='alert alert-success'>
  <button class='close' data-dismiss='alert' type='button'>x</button>
  Your file is being processed. You will receive an email with a link to download your file once it is completed.</div>`;
  $('.results').html(html);
  $(button).attr('disabled', 'disabled');
}
