import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { labelStyle } from "../styles/GalStyles";

const selectChange = (onChange) => (data) => {
  // build a synthetic change event to be consistent with regular form fields onChange
  onChange({ target: { value: data } });
};

export const LocationSelect = ({
  locations,
  value,
  onChange,
  allowMultiple,
}) => {
  return (
    <div className="locations react-select" id="location-selector">
      <label htmlFor="gal-location" style={labelStyle}>
        Locations
      </label>

      <Select
        multi={allowMultiple}
        options={_.sortBy(locations, ["id", "name"])}
        value={value}
        onChange={selectChange(onChange)}
        placeholder="All Locations"
        valueKey="id"
        labelKey="name"
        tabSelectsValue={false}
        openOnFocus
        optionRenderer={({ name, id }) => (
          <div className="option" data-value={id}>
            <span>
              ({id}) {name}
            </span>
          </div>
        )}
        valueRenderer={({ name, id }) => (
          <div className="value" data-value={id}>
            <span>
              ({id}) {name}
            </span>
          </div>
        )}
      />
    </div>
  );
};

const objectOrArray = [PropTypes.array, PropTypes.object];

LocationSelect.propTypes = {
  locations: PropTypes.array,
  value: PropTypes.oneOfType(objectOrArray),
  onChange: PropTypes.func,
};

export default LocationSelect;
