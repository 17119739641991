import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LocationSelect from "./LocationSelect";
import {
  fetchControlFilesSelectLocations,
  enableSubmit,
} from "../actions/GalActions";

export class LocationSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.selectLocations = this.selectLocations.bind(this);
  }

  selectLocations(event) {
    const locations = event.target.value;
    const { store } = this.props;
    store.dispatch(enableSubmit());
    const currentGroup = store.getState().galSelected.cascading_gal.group;
    store.dispatch(fetchControlFilesSelectLocations(locations, currentGroup));
  }

  render() {
    return (
      <LocationSelect
        multi={true}
        onChange={this.selectLocations}
        allowMultiple={true}
        value={this.props.selectedLocations}
        locations={this.props.locations}
      />
    );
  }
}

const objectOrArray = [PropTypes.array, PropTypes.object];

LocationSelectContainer.propTypes = {
  store: PropTypes.object.isRequired,
  locations: PropTypes.array,
  selectedLocations: PropTypes.oneOfType(objectOrArray),
};

function mapStateToProps(state) {
  return {
    locations: state.galSearch.locations,
    selectedLocations: state.galSelected.cascading_gal.locations,
  };
}

export default connect(mapStateToProps)(LocationSelectContainer);
