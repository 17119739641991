export const ENABLE_SUBMIT = 'ENABLE_SUBMIT';
export const DISABLE_SUBMIT = 'DISABLE_SUBMIT';

export const REQUEST_GROUP_SEARCH = 'REQUEST_GROUP_SEARCH';
export const RECEIVE_GROUP_SEARCH = 'RECEIVE_GROUP_SEARCH';

export const REFRESH_LOCATIONS = 'REFRESH_LOCATIONS';
export const REFRESH_ACCOUNTS = 'REFRESH_ACCOUNTS';

export const REQUEST_CONTROL_FILES = 'REQUEST_CONTROL_FILES';
export const RECEIVE_CONTROL_FILES = 'RECEIVE_CONTROL_FILES';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

export const RECEIVE_CP_REPORT = 'RECEIVE_CP_REPORT'
export const RECEIVE_CP2_REPORT = 'RECEIVE_CP2_REPORT';

export const LOAD_ACCOUNTS_LOCATIONS = 'LOAD_ACCOUNTS_LOCATIONS';
export const CLEAR_ACCOUNTS_LOCATIONS_CONTROL_FILES = 'CLEAR_ACCOUNTS_LOCATIONS';

export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_ACCOUNTS = 'SELECT_ACCOUNTS';
export const SELECT_LOCATIONS = 'SELECT_LOCATIONS';
export const SELECT_CONTROL_FILE = 'SELECT_CONTROL_FILE';
export const SELECT_PRODUCTS = 'SELECT_PRODUCTS';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const SELECT_START_DATE = 'SELECT_START_DATE';
export const SELECT_END_DATE = 'SELECT_END_DATE';
export const CLEAR_GAL = 'CLEAR_GAL';

export const ANALYTICS_FIELD_CHECKED = 'ANALYTICS_FIELD_CHECKED';

export const CLEAR_DATA = 'CLEAR_DATA';
