import React, { Component } from "react";
import PropTypes from "prop-types";

const handleClick = (callback) => (event) => {
  callback({ target: { value: event.target.checked } });
};

export const CustomerCertification = ({ value, name, onChange }) => {
  return (
    <div className="form-group text required data_contribution_files_message">
      <input
        id="customer_certification"
        value={value}
        type="checkbox"
        name="customer_certification"
        onChange={handleClick(onChange)}
        checked={value}
        required
      />
      <label htmlFor="customer_certification">
        &nbsp;I, {name}, certify that the information submitted above is
        accurate and complete.
      </label>
    </div>
  );
};

CustomerCertification.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default CustomerCertification;
