import React, { Component } from "react";
import PropTypes from "prop-types";
import CloudUpload from "./CloudUpload";

class UploadFileForm extends Component {
  state = {
    error: false,
    success: false,
    errorMessage: "",
  };

  fileUploaded = ({ filename, filePath }) => {
    const postBody = JSON.stringify({
      ...{ filename: filename, filepath: filePath },
      ...this.props.formData(),
    });
    return fetch(`${this.props.uploadPath}/upload`, {
      method: "POST",
      body: postBody,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.props.authToken,
      },
    })
      .then((resp) => {
        this.setState({ success: true });
        if (!resp.ok) {
          resp.json().then((data) => {
            this.setState({ errorMessage: data.errors, error: true, success: false });
          }
        )};
      })
      .catch((err) => {
        console.log("ERROR! on file save");
        console.log(err);
        this.setState({ error: true });
      });
  };

  render() {
    return (
      <form id="fileupload" className="file-upload-form" noValidate>
        <div className="row">
          <div className="col-sm-7">
            <div className="panel panel-default bg-fa">
              <div className="panel-heading">
                <h3 className="panel-title">{this.props.uploadTitle}</h3>
              </div>

              <div className="form-body panel-body">
                {this.props.children}

                <div className="row fileupload-buttonbar row-spaced">
                  <div className="col-sm-9">
                    <CloudUpload
                      onSent={this.fileUploaded}
                      enableSend={this.props.enableSend}
                      signingUrl={`${this.props.uploadPath}/upload_url`}
                    />
                  </div>
                </div>

                <div className="center-block row row-spaced">
                  <div
                    className="col-sm-6 alert alert-danger"
                    id="upload-error"
                    style={this.state.error ? {} : { display: "none" }}
                  >
                    An error occured during file upload: {this.state.errorMessage}
                  </div>

                  <div
                    className="col-sm-6 alert alert-success"
                    id="upload-success"
                    style={this.state.success ? {} : { display: "none" }}
                  >
                    Your file upload is complete.
                  </div>
                </div>

                <br />

                <p className="text-info">
                  To upload multiple files select each individually and upload
                  one-at-a-time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

UploadFileForm.propTypes = {
  formData: PropTypes.func,
  uploadPath: PropTypes.string,
  uploadTitle: PropTypes.string,
  enableSend: PropTypes.bool,
  authToken: PropTypes.string,
};

UploadFileForm.defaultProps = {
  formData: () => {
    return {};
  },
  uploadPath: "/file_transfers",
  enableSend: true,
};

export default UploadFileForm;
