import React, { Component } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import materialTheme from "../../../utils/Theme";
import store from "../../../ReduxStore";
import ClearProfileTable from "./ClearProfileTable"
import ClearProfileSearch from "./ClearProfileSearch"
import PageHeader from "../../layout/PageHeader"

type ClearProfileProps = {
  authenticityToken?: any,
  endDate?: String,
  store?: any,
  galGroup?: any,
  emptyTitle?: any,
  emptyMsg?: any,
  pageTitle?: any,
  startDate?: String,
};

class ClearProfile extends Component<ClearProfileProps, {}> {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={materialTheme}>
          <div className="results"></div>
          <PageHeader pageTitle={this.props.pageTitle} />

          <div className="row">
            <div className="col-sm-3">
              <ClearProfileSearch
                store={store}
                galGroup={this.props.galGroup}
                authenticityToken={this.props.authenticityToken}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
              />
            </div>
            <div className="col-sm-9">
              <ClearProfileTable store={store} emptyTitle={this.props.emptyTitle} emptyMsg={this.props.emptyMsg} />
            </div>
          </div>
        </ThemeProvider>
      </Provider>
    );
  }
}
export default ClearProfile;
