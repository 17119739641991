import React, { Component } from 'react'
import PropTypes from "prop-types";

class PageHeader extends Component {
  render() {
    return (
      <div className="page-header">
        <h1>{this.props.pageTitle}</h1>
        <div className="clearfix"></div>
      </div>
    )
  }
}

PageHeader.propTypes = {
  pageTitle: PropTypes.string,
};

export default PageHeader;
