import React, { Component } from "react";
import CustomerCertification from "./CustomerCertification";
import AcceptChange from "./AcceptChange";
import UploadFileForm from "./UploadFileForm";

class InquiryFileAcceptForm extends Component {
  state = {
    customerCert: false,
    production: false
  };

  formData = () => {
    return {
      customer_certification: this.state.customerCert,
      production: this.state.production,
    };
  };

  render() {
    return (
      <UploadFileForm
        formData={this.formData}
        uploadPath={this.props.uploadPath}
        authToken={this.props.authToken}
        uploadTitle="Select a Inquiry file for Upload"
        enableSend={this.state.customerCert}
      >
        <AcceptChange
          value={this.state.production}
          name={this.props.userName}
          onChange={(event) => {
            this.setState({ production: event.target.value });
          }}
        />
        <CustomerCertification
          value={this.state.customerCert}
          name={this.props.userName}
          onChange={(event) => {
            this.setState({ customerCert: event.target.value });
          }}
        />
        <div
          className="col-sm-12 alert alert-danger"
          id="form-validation-error"
          style={this.state.customerCert ? { display: "none" } : {}}
        >
          You must review and acknowledge the Customer Certification
        </div>
      </UploadFileForm>
    );
  }
}

InquiryFileAcceptForm.defaultProps = {
  uploadPath: "/Inquiry_files"
};

export default InquiryFileAcceptForm;

