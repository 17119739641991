import { flashErrors, flashSuccess } from '../utils/FlashUtils';
import { receiveCP2Report } from './ClearProfile2Actions';
import { receiveCPReport } from './ClearProfileActions';

export function submitTADetails(details, button) {
  const removedProducts = details.removed_products;
  const gal = details.cascading_gal;

  const products = gal.products.filter(product =>
    removedProducts.indexOf(product) === -1,
  );

  const request = {
    cascading_gal: {
      group_id: gal.group ? gal.group.id : null,
      account_ids: Array.isArray(gal.accounts) ? gal.accounts.map(account => account.id) : [gal.accounts.id],
      location_ids: Array.isArray(gal.locations) ? gal.locations.map(location => location.id) : [gal.locations.id],
      control_file_id: gal.control_file ? gal.control_file.id : null,
      products,
    },
    start_date: details.start_date,
    end_date: details.end_date,
    include_private: details.include_private,
  };

  return function () {
    return $.post('/trial_analyses.json', request)
      .fail(res => flashErrors(res.responseText))
      .always(() => {
        flashSuccess(button);
      });
  };
}

export function submitCCDetails(details, button) {

  const request = {
    start_date: details.start_date,
    end_date: details.end_date,
    month: details.month
  };

  return function () {
    return $.post('/client_cancellation_reports.json', request)
      .fail(res => flashErrors(res.responseText))
      .always(() => {
        flashSuccess(button);
      });
  };
}

export function submitCCMDetails(month, button) {

  const request = {
    month: month
  };

  return function () {
    return $.post('/client_cancellation_reports.json', request)
      .fail(res => flashErrors(res.responseText))
      .always(() => {
        flashSuccess(button);
      });
  };
}

export function submitCPReport(details, button) {
  const gal = details.cascading_gal;

  const request = {
    cascading_gal: {
      group_id: gal.group ? gal.group.id : null,
      account_ids: Array.isArray(gal.accounts) ? gal.accounts.map(account => account.id) : [gal.accounts.id],
      location_ids: Array.isArray(gal.locations) ? gal.locations.map(location => location.id) : [gal.locations.id]
    },
    start_date: details.start_date,
    end_date: details.end_date
  };

  return function (dispatch) {
    return $.get('/reports/clear_profile.json', request)
      .fail(res => flashErrors(res.responseText))
      .always(res => {
        dispatch(receiveCPReport(res));
      });
  };
}


export function submitCP2Report(details, button) {
  const gal = details.cascading_gal;

  const request = {
    cascading_gal: {
      group_id: gal.group ? gal.group.id : null,
      account_ids: Array.isArray(gal.accounts) ? gal.accounts.map(account => account.id) : [gal.accounts.id],
      location_ids: Array.isArray(gal.locations) ? gal.locations.map(location => location.id) : [gal.locations.id]
    },
    start_date: details.start_date,
    end_date: details.end_date
  };

  return function (dispatch) {
    return $.get('/reports/clear_profile2.json', request)
      .fail(res => flashErrors(res.responseText))
      .always(res => {
        dispatch(receiveCP2Report(res));
      });
  };
}
