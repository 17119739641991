export default function galSearch(state = {
  isFetching: false,
  groupQuery: '',
  groups: [],
  accounts: [],
  locations: [],
  controlFiles: [],
  products: [],
  scorecards: [],
}, action) {
  switch (action.type) {
    case 'RESET_GROUP_SEARCH':
      return Object.assign({}, state, {
        groupQuery: '',
      });
    case 'REQUEST_GROUP_SEARCH':
      return Object.assign({}, state, {
        isFetching: true,
        groupQuery: action.groupQuery,
      });
    case 'RECEIVE_GROUP_SEARCH':
      return Object.assign({}, state, {
        isFetching: false,
        groups: action.groups || [],
        groupQuery: action.groupQuery,
      });
    case 'LOAD_ACCOUNTS_LOCATIONS':
      return Object.assign({}, state, {
        accounts: action.accounts,
        locations: action.locations,
      });
    case 'REFRESH_LOCATIONS':
      return Object.assign({}, state, {
        locations: action.locations,
      });
    case 'REFRESH_ACCOUNTS':
      return Object.assign({}, state, {
        accounts: action.accounts,
      });
    case 'REQUEST_CONTROL_FILES':
      return Object.assign({}, state, {
        isFetching: true,
      });
    case 'RECEIVE_CONTROL_FILES':
      return Object.assign({}, state, {
        isFetching: false,
        controlFiles: action.controlFiles,
      });
    case 'REQUEST_PRODUCTS':
      return Object.assign({}, state, {
        isFetching: true,
      });
    case 'RECEIVE_PRODUCTS':
      return Object.assign({}, state, {
        isFetching: false,
        products: action.products,
      });
    case 'REQUEST_SCORECARDS':
      return Object.assign({}, state, {
        isFetching: true,
      });
    case 'RECEIVE_SCORECARDS':
      return Object.assign({}, state, {
        isFetching: false,
        scorecards: action.scorecards,
      });
    default:
      return state;
  }
}
