import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ControlFileSelect from "./ControlFileSelect";
import { getLocationsFromGroup } from "../utils/GalUtils";
import {
  selectControlFile,
  enableSubmit,
  fetchProducts,
} from "../actions/GalActions";

export class ControlFileSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.selectControlFile = this.selectControlFile.bind(this);
  }

  selectControlFile(event) {
    const controlFile = event.target.value;
    const { store } = this.props;
    const state = store.getState();
    let controlFiles;

    store.dispatch(enableSubmit());
    store.dispatch(selectControlFile(controlFile));

    let locations = state.galSelected.cascading_gal.locations;
    if (locations.length === 0) {
      const currentGroup = state.galSelected.cascading_gal.group;
      locations = getLocationsFromGroup(currentGroup);
    }

    if (controlFile) {
      controlFiles = [controlFile];
    } else {
      controlFiles = state.galSearch.controlFiles;
    }

    store.dispatch(fetchProducts(locations, controlFiles));
  }

  render() {
    return (
      <ControlFileSelect
        controlFiles={this.props.controlFiles}
        value={this.props.selectedControlFile}
        onChange={this.selectControlFile}
      />
    );
  }
}

ControlFileSelectContainer.propTypes = {
  store: PropTypes.object.isRequired,
  controlFiles: PropTypes.array,
  selectedControlFile: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    controlFiles: state.galSearch.controlFiles,
    selectedControlFile: state.galSelected.cascading_gal.control_file,
  };
}

export default connect(mapStateToProps)(ControlFileSelectContainer);
