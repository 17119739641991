import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { labelStyle } from "../styles/GalStyles";
import { getLocationsFromGroup } from "../utils/GalUtils";
import {
  fetchGroupSearch,
  fetchControlFiles,
  selectGroup,
  loadAccountsLocations,
  clearAccountsLocationsControlFiles,
  enableSubmit
} from "../actions/GalActions";

export class GroupSelect extends Component {
  constructor(props) {
    super(props);
    this.groupInputRef = React.createRef();  // Changed from string ref to createRef
    this.loadGroups = this.loadGroups.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
  }

  componentDidMount() {
    const { galGroup } = this.props;
    if (galGroup && galGroup.name) {
      this.groupInputRef.current.options = this.loadGroups(galGroup.name);
      this.groupInputRef.current.setValue(galGroup);
    }
  }

  loadGroups(input) {
    if (input.length >= 1) {
      return this.props.store
        .dispatch(fetchGroupSearch(input))
        .then((res) => ({ options: res.groups }));
    }
    return null;
  }

  selectGroup(group) {
    const { store } = this.props;
    store.dispatch(selectGroup(group));
    store.dispatch(enableSubmit());
    if (group) {
      const locations = getLocationsFromGroup(group);
      store.dispatch(loadAccountsLocations(group.accounts, locations));
      store.dispatch(fetchControlFiles(locations));
    } else {
      store.dispatch(clearAccountsLocationsControlFiles());
    }
  }

  render() {
    return (
      <div className="groups react-select">
        <label style={labelStyle}>* Group</label>
        <Select
          onInputChange={this.loadGroups}
          value={this.props.selectedGroup}
          onChange={this.selectGroup}
          placeholder="Select a Group"
          valueKey="id"
          labelKey="text"
          ref={this.groupInputRef}  // Updated to use createRef
          options={this.props.groups}
          tabSelectsValue={false}
          optionRenderer={({ text, id }) => (
            <div className="option" data-value={id}>
              <span>{text}</span>
            </div>
          )}
          valueRenderer={({ text, id }) => (
            <div className="value" data-value={id}>
              <span>{text}</span>
            </div>
          )}
        />
      </div>
    );
  }
}

const objectOrArray = [PropTypes.array, PropTypes.object];

GroupSelect.propTypes = {
  store: PropTypes.object.isRequired,
  galGroup: PropTypes.object,
  groupQuery: PropTypes.string,
  groups: PropTypes.oneOfType(objectOrArray).isRequired,
  selectedGroup: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    groupQuery: state.galSearch.groupQuery,
    groups: state.galSearch.groups,
    selectedGroup: state.galSelected.cascading_gal.group,
    isFetching: state.galSearch.isFetching,
  };
}

export default connect(mapStateToProps)(GroupSelect);
