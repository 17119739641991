import React, { Component } from "react";
import UserSelect from "./UserSelect";
import DeptSelect from "./DeptSelect";
import UploadFileForm from "./UploadFileForm";

const FileRecipient = ({ clarityUser, departments, recipient, onChange }) => {
  return clarityUser ? (
    <UserSelect value={recipient} onChange={onChange} />
  ) : (
    <DeptSelect
      departments={departments}
      value={recipient}
      onChange={onChange}
    />
  );
};

class SecureFileForm extends Component {
  state = {
    recipient: this.props.recipient,
    message: this.props.message || "",
  };

  valid = () => {
    return !!this.state.recipient && !!this.state.message;
  };

  formChange = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  formData = () => {
    return { recipient: this.state.recipient, message: this.state.message };
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <h1>{this.props.title} </h1>
          <div className="clearfix"></div>
        </div>

        <div className="row">
          <div className="col-sm-7">
            <div className="well">
              <div
                className="panel-body"
                dangerouslySetInnerHTML={{ __html: this.props.upload_text }}
              />
            </div>
          </div>
        </div>

        <UploadFileForm
          formData={this.formData}
          uploadPath="/file_transfers"
          authToken={this.props.authToken}
          uploadTitle={this.props.detail_text}
          enableSend={this.valid()}
          data-testid="react-s3-uploader"
        >
          <FileRecipient
            recipient={this.state.recipient}
            clarityUser={this.props.clarityUser}
            departments={this.props.departments}
            onChange={this.formChange("recipient")}
          />
          <div className="form-group text required data_contribution_files_message">
            <label
              className="text required control-label"
              htmlFor="data_contribution_files_message"
            >
              <abbr title="required">*</abbr> Message
            </label>
            <textarea
              name="message"
              value={this.state.message}
              onChange={this.formChange("message")}
              rows="5"
              className="text required form-control"
              required="required"
              aria-required="true"
              id="data_contribution_files_message"
              data-fv-field="message"
            ></textarea>
          </div>
          <div
            className="col-sm-12 alert alert-danger"
            id="form-validation-error"
            style={this.valid() ? { display: "none" } : {}}
          >
            Please specify a recipient and message before submitting your file.
          </div>
        </UploadFileForm>
      </div>
    );
  }
}

export default SecureFileForm;
