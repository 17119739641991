import React, { Component } from "react";
import PropTypes from "prop-types";

export default class EnableMlaCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = { mlaEnabled: this.props.mlaEnabled };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleCheckbox(event) {
    const self = this;
    const checkbox = event.target;
    if (checkbox.checked) {
      $(".mla_checkbox").each((_, box) => {
        !box.checked ? box.click() : null;
        self.setState({ mlaEnabled: true });
      });
    } else {
      $(".mla_checkbox").each((_, box) => {
        box.checked ? box.click() : null;
        self.setState({ mlaEnabled: false });
      });
    }
  }

  render() {
    return (
      <div
        className={`form-group optional boolean control_file_product_options_${this.props.productName}_enable_mla`}
      >
        <div className="checkbox">
          <input
            value="0"
            type="hidden"
            name={`control_file[product_options][${this.props.productName}][enable_mla]`}
          />
          <label
            htmlFor={`control_file_product_options_${this.props.productName}_enable_mla`}
          >
            <input
              className="boolean optional has_popover mla_checkbox"
              data-trigger="hover"
              data-container="body"
              data-content="Separate MLA addendum must be signed"
              type="checkbox"
              name={`control_file[product_options][${this.props.productName}][enable_mla]`}
              id={`control_file_product_options_${this.props.productName}_enable_mla`}
              onChange={this.handleCheckbox}
              checked={this.state.mlaEnabled}
              value={this.state.mlaEnabled ? "1" : "0"}
            />
            Enable Military Lending Act Status
          </label>
        </div>
      </div>
    );
  }
}

EnableMlaCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  mlaEnabled: PropTypes.bool.isRequired,
};
