import { combineReducers } from 'redux';
import galSearch from './GalSearchReducer';
import { galSelected } from './GalSelectedReducer';
import { clearProfile } from './ClearProfileReducer';
import { clearProfile2 } from './ClearProfile2Reducer';

const rootReducer = combineReducers({
  galSearch,
  galSelected,
  clearProfile,
  clearProfile2,
});

export default rootReducer;
