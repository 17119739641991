const defaultState = {
  disabled: false,
  removed_products: [],
  cascading_gal: {
    group: null,
    accounts: [],
    locations: [],
    control_file: null,
    products: [],
  },
  start_date: null,
  end_date: null,
  include_private: false,
};

export function galSelected(state = defaultState, action) {
  switch (action.type) {
    case 'DISABLE_SUBMIT': {
      return Object.assign({}, state, { disabled: true });
    }
    case 'ENABLE_SUBMIT': {
      return Object.assign({}, state, { disabled: false });
    }
    case 'ANALYTICS_FIELD_CHECKED': {
      return {...state, include_private: !state.include_private}
    }
    case 'SELECT_GROUP': {
      return Object.assign({}, state, {
        cascading_gal: { ...state.cascading_gal, group: action.group },
      });
    }
    case 'SELECT_ACCOUNTS': {
      return Object.assign({}, state, {
        cascading_gal: { ...state.cascading_gal, accounts: action.accounts },
      });
    }
    case 'SELECT_LOCATIONS': {
      return Object.assign({}, state, {
        cascading_gal: { ...state.cascading_gal, locations: action.locations },
      });
    }
    case 'CLEAR_GAL': {
      return {...defaultState};
    }
    case 'CLEAR_ACCOUNTS_LOCATIONS': {
      return Object.assign({}, state, {
        cascading_gal: { ...state.cascading_gal,
          accounts: [],
          locations: [],
          control_file: null,
        },
      });
    }
    case 'SELECT_CONTROL_FILE': {
      return Object.assign({}, state, {
        cascading_gal: { ...state.cascading_gal, control_file: action.controlFile },
      });
    }
    case 'SELECT_START_DATE': {
      return Object.assign({}, state, {
        start_date: action.startDate,
      });
    }
    case 'SELECT_END_DATE': {
      return Object.assign({}, state, {
        end_date: action.endDate,
      });
    }
    case 'SELECT_PRODUCTS': {
      return Object.assign({}, state, {
        cascading_gal: { ...state.cascading_gal,
          products: [...action.products],
        },
      });
    }
    case 'SELECT_PRODUCT': {
      const index = state.removed_products.indexOf(action.product);
      return Object.assign({}, state, {
        removed_products: [
          ...state.removed_products.slice(0, index),
          ...state.removed_products.slice(index + 1),
        ],
        cascading_gal: { ...state.cascading_gal,
          products: [...state.cascading_gal.products, action.product],
        },
      });
    }
    case 'REMOVE_PRODUCT': {
      const index = state.cascading_gal.products.indexOf(action.product);
      if (index === -1) {
        return state;
      }
      return Object.assign({}, state, {
        removed_products: [...state.removed_products, action.product],
        cascading_gal: { ...state.cascading_gal,
          products: [
            ...state.cascading_gal.products.slice(0, index),
            ...state.cascading_gal.products.slice(index + 1),
          ],
        },
      });
    }
    default:
      return state;
  }
}
