import { CLEAR_DATA, RECEIVE_CP_REPORT } from '../constants/ActionTypes';

export function receiveCPReport(data) {
  return {
    type: RECEIVE_CP_REPORT,
    data: data,
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA
  };
}
