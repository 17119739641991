import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

export class UserSelect extends Component {
  userSearch = (search) => {
    if (!search) {
      return Promise.resolve({ options: [] });
    }
    return fetch(`/users/list?search_term=${search}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return { options: json.results };
      });
  };

  selectChange = (data) => {
    // build a synthetic change event to be consistent with regular form fields onChange
    this.props.onChange({ target: { value: data } });
    this.props;
  };

  render() {
    return (
      <div className="users react-select" id="users-selector" data-testid="user-select">
        <label
          className="string required control-label"
          htmlFor="select-user-id"
        >
          <abbr title="required">*</abbr> User
        </label>

        <Select.Async
          autoload={false}
          loadOptions={this.userSearch}
          value={this.props.value}
          onChange={this.selectChange}
          placeholder="Select User"
          valueKey="id"
          labelKey="text"
          tabSelectsValue={false}
          openOnFocus
          name="select-user-id"
          className="input-user"
          optionRenderer={({ text, id }) => (
            <div className="option" data-value={id}>
              <span>{text}</span>
            </div>
          )}
          valueRenderer={({ text, id }) => (
            <div className="value" data-value={id}>
              <span>{text}</span>
            </div>
          )}
        />
      </div>
    );
  }
}

UserSelect.propTypes = {
  users: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default UserSelect;
