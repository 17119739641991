import React, { Component } from "react";
import PaperSheet from "../layout/PaperSheet"

type DataSeederSubTableProps = {
  rowData?: {
    file_location: string
  };
};

class DataSeederSubTable extends Component<DataSeederSubTableProps, {}> {
  render() {
    const rowData = this.props.rowData;
    const title = "The data file for this seed can be found at:"

    return (
      <div style={{padding: "5em", background: "#EEE"}}>
        <PaperSheet title={title} content={rowData.file_location} />
      </div>
    );
  }
}

export default DataSeederSubTable;
